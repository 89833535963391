import { APP_MODE } from 'applicationConstants/envVariables';
import getText from '../utils/getText';
import { ALLOWED_MODE, LENDERS, LENDERS_TYPE } from './enums';
import { usaStates } from './states';
import { IDictionary, ISelectOption } from '../types/otherData';

export const PROCEED_CHARGE_LINK = 'https://credcompare.proceed.finance/login';
export const LENDING_CLUB_PORTAL = APP_MODE === ALLOWED_MODE.production
  ? 'https://www.lendingclub.com/patientsolutions/app/login'
  : 'https://sandbox.lendingclub.com/patientsolutions/app/login';
export const COLORS = {
  mainBlue: '#32207E',
  activeBlue: '#2800f0',
  darkBlue: '#000051',
  selectedBlue: '#0035ff',
  softBlueText: '#336EA9D9',
  softBlueLight: '#F7F9FD',
  softBlueDark: '#E7E9F3',
  declinedRed: '#D22E2C',
  disabled: '#e4e4e9',
  background: '#f4f7ff',
  selectedBackground: 'rgba(0, 53, 255, 0.1)',
  actionCardBorder: '#DBDFED',
  gray: '#b3b1bd',
  border: '#E5EAF9',
  darkGray: '#9997A5',
  lightGray: 'rgba(9, 2, 36, 0.5)',
  lightBlackRock: 'rgba(9, 2, 36, 0.7)',
  superLightGrey: '#F5F7FC',
  lightestBlackRock: 'rgba(9, 2, 36, 0.37)',
  superLightBlackRock: 'rgba(9, 2, 36, 0.2)',
  evenLightestBlackRock: 'rgba(9, 2, 36, 0.1)',
  black: '#090224',
  white: '#FFFFFF',
  trackColor: '#e2ecf3',
  buttonBackground: '#F7F9FF',
  pink: '#FD00C3',
  violet: '#D501DE',
  controlBackground: '#f6fbfe',
  controlColor: 'rgba(71,73,90,0.5)',
  green: '#3CB400',
  orange: 'rgb(235, 122, 40)',
  dividersWithText: '#342088B2',
  typographySecondary: '#090224B2',
};

export const BORDER_STYLES = {
  actionCardBorder: `0.6px solid ${COLORS.actionCardBorder}`,
};

export const SHADOW_STYLES = {
  generalBoxShadow: '0px 14px 24px rgba(52, 32, 136, 0.3)',
  actionCardShadow: '0 2px 9px rgba(127, 147, 174, 0.1)',
  lightBoxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.1)',
};

export const USER_ROLES = {
  guest: 50,
  frontDesk: 100,
  regionalSales: 200,
  locationAdmin: 1000,
  regionalManager: 3000,
  networkAdmin: 5000,
  support: 7000,
} as const;

export type USER_ROLES_TYPE = (typeof USER_ROLES)[keyof typeof USER_ROLES];

export const ELEMENTS_PER_PAGE = 8;

export const STATE_OPTIONS = usaStates
  .sort((a: any, b: any) => (a.value < b.value ? -1 : 1))
  .map((item) => ({
    label: item.value,
    value: item.value,
  }));

export const PROCEED_EMPLOYMENT_ENUM: Record<string, string> = {
  employed: 'Employed',
  selfEmployed: 'Self-Employed',
  notEmployed: 'Unemployed',
  retired: 'Retired',
};

export const POWERPAY_EMPLOYMENT_ENUM = {
  employed: 'Employed',
  self_employed: 'Self-Employed',
  retired: 'Retired',
  familyManager: 'Family Manager / Other',
} as const;

export const POWERPAY_EMPLOYMENT_OPTIONS = Object.values(POWERPAY_EMPLOYMENT_ENUM)
  .map((value: string) => ({
    label: value,
    value,
  })) as Array<ISelectOption>;

export const OCCUPATION_ENUM: Record<string, string> = {
  management: 'Management',
  businessAndFinancialOperations: 'Business and Financial Operations',
  computerAndMathematical: 'Computer and Mathematical',
  architectureAndEngineering: 'Architecture and Engineering',
  lifePhsyicalAndSocialScience: 'Life, Physical, and Social Science',
  communityAndSocialService: 'Community and Social Service',
  legal: 'Legal',
  educationalInstructionAndLibrary: 'Educational Instruction and Library',
  artsDesignEntertainmentSportsAndMedia: 'Arts, Design, Entertainment, Sports, and Media',
  healthcarePractitionersAndTechnical: 'Healthcare Practitioners and Technical',
  healthcareSupport: 'Healthcare Support',
  protectiveService: 'Protective Service',
  foodPreparationAndServingRelated: 'Food Preparation and Serving Related',
  buildingAndGroundsCleaningAndMaintenance: 'Building and Grounds Cleaning and Maintenance',
  personalCareAndService: 'Personal Care and Service',
  salesAndRelated: 'Sales and Related',
  officeAndAdministrativeSupport: 'Office and Administrative Support',
  farmingFishingAndForestry: 'Farming, Fishing, and Forestry',
  constructionAndExtraction: 'Construction and Extraction',
  installationMaintenanceAndRepair: 'Installation, Maintenance, and Repair',
  production: 'Production',
  transportationAndMaterialMoving: 'Transportation and Material Moving',
  militarySpecific: 'Military Specific',
};

export const OCCUPATION_OPTIONS = [
  'Accountant',
  'Auditor',
  'Bookkeeper',
  'Payroll Clerk',
  'Tax Preparer',
  'Administrative Assistant',
  'Office Manager',
  'Agriculture',
  'Architect',
  'Engineer',
  'Media',
  'Designer',
  'Mechanic',
  'Technician',
  'Driver',
  'Custodian',
  'Groundskeeper',
  'Janitor',
  'Maintenance Worker',
  'Finance Manager',
  'Director of Finance',
  'Analyst',
  'Counselor',
  'Social Worker',
  'Carpenter',
  'Electrician',
  'Plumber',
  'Chef',
  'Restaurant Manager',
  'Waiter',
  'Bartender',
  'Line cook',
  'Customer Service Representative',
  'Customer Service Manager',
  'Home Manager',
  'Disability',
  'Teacher',
  'Professor',
  'Librarian',
  'Teachers Assistant',
  'Educator',
  'Actor',
  'Athlete',
  'Coach',
  'Dancer',
  'Musician',
  'Chief Executive Officer (CEO)',
  'Chief Financial Officer (CFO)',
  'Chief Operating Officer (COO)',
  'Vice President (VP)',
  'Director',
  'Police Officer',
  'Firefighter',
  'Lawyer',
  'Judge',
  'Security Guard',
  'Corrections Officer',
  'Doctor',
  'Nurse',
  'Pharmacist',
  'Dentist',
  'Optometrist',
  'Veterinarian',
  'Physical Therapist',
  'Occupational Therapist',
  'Speech Therapist',
  'Psychologist',
  'Assembly Line Worker',
  'Factory Worker',
  'Machinist',
  'Welder',
  'Quality Control Inspector',
  'Army',
  'Navy',
  'Air Force',
  'Marine Corps',
  'Coast Guard',
  'Hair Stylist',
  'Massage Therapist',
  'Nail Technician',
  'Personal Trainer',
  'Mail Carrier',
  'Mail Clerk',
  'Real Estate Agent',
  'Real Estate Broker',
  'Appraiser',
  'Property Manager',
  'Loan Officer',
  'Retail Salesperson',
  'Cashier',
  'Stockperson',
  'Retail Manager',
  'Retired',
  'Sales Representative',
  'Marketing Manager',
  'Account Executive',
  'Scientist',
  'Researcher',
  'Data Analyst',
  'Self Employed',
  'Software Engineer',
  'Developer',
  'QA Tester',
  'Truck Driver',
  'Bus Driver',
  'Train Engineer',
  'Pilot',
  'Taxi Driver',
  'Rideshare Driver',
  'Clergy',
]
  .map((x) => ({
    label: x,
    value: x,
  }));

export const ID_ENUM = {
  driverLicense: 'driver_license',
  stateId: 'state_id',
  passport: 'passport',
  militaryId: 'military_id',
};

export const UgaFinancingEnum = {
  risa: 'RISA',
  bank: 'Bank',
  pathward: 'Pathward',
} as const;

export const UGA_FINANCING_OPTIONS = [{
  label: UgaFinancingEnum.risa,
  value: UgaFinancingEnum.risa,
}, {
  label: UgaFinancingEnum.bank,
  value: UgaFinancingEnum.bank,
}, {
  label: 'Pathward Bank',
  value: UgaFinancingEnum.pathward,
}];

export const UgaTypeEnum = {
  open: 'Open',
  closed: 'Closed',
} as const;

export const UGA_TYPE_OPTIONS = Object.values(UgaTypeEnum).map((value: string) => ({
  label: value,
  value,
}));

export const GreatAmericaProgramEnum = {
  standard: 'Standard',
  finWise: 'FinWise',
};

export const GREAT_AMERICA_PROGRAM_OPTIONS = Object.values(GreatAmericaProgramEnum).map((value: string) => ({
  label: value,
  value,
}));

export const OWNING_ENUM = {
  rent: 'rent',
  own: 'own',
  mortgage: 'mortgage',
  free_accommodation: 'free_accommodation',
};

export const OWNING_OPTIONS = Object.values(OWNING_ENUM).map((key: string) => ({
  label: getText(`housingStatus.${key}`),
  value: key,
}));

export const DOCUMENT_ENUM: IDictionary = {
  dl: 'DL',
  pp: 'PP',
  ml: 'ML',
  gt: 'GT',
  id: 'ID',
  gc: 'GC',
  ra: 'RA',
  ot: 'OT',
};

export const DOCUMENT_OPTIONS = Object.keys(DOCUMENT_ENUM).map((key: string) => ({
  label: getText(`documentEnum.${key}`),
  value: DOCUMENT_ENUM[key],
}));

export const PAYCHECK_ENUM = {
  weekly: 'weekly',
  bi_weekly: 'bi_weekly',
  twice_monthly: 'twice_monthly',
  monthly: 'monthly',
} as const;

export type PAYCHECK_TYPE = typeof PAYCHECK_ENUM[keyof typeof PAYCHECK_ENUM];

export const PAYCHECK_OPTIONS = Object.keys(PAYCHECK_ENUM).map((key) => ({
  label: getText(`paycheck.${key}`),
  value: key as PAYCHECK_TYPE,
}));

export const MAX_DICTIONARY_SIZE = 1000;

export const USER_ROLES_OPTIONS = [{
  label: getText(`roles.${USER_ROLES.regionalManager}`),
  value: USER_ROLES.regionalManager,
}, {
  label: getText(`roles.${USER_ROLES.regionalSales}`),
  value: USER_ROLES.regionalSales,
}, {
  label: getText(`roles.${USER_ROLES.locationAdmin}`),
  value: USER_ROLES.locationAdmin,
}, {
  label: getText(`roles.${USER_ROLES.frontDesk}`),
  value: USER_ROLES.frontDesk,
}];

export const ae2TypeOfIdOptions = [
  { label: "Driver's license", value: 'drivers-license' },
  { label: 'State ID', value: 'state-id' },
  {
    label: 'Federal or Government Photo ID',
    value: 'federal-or-government-photo-id',
  },
  { label: 'Military Photo ID', value: 'military-photo-id' },
  { label: 'Passport', value: 'passport' },
  { label: 'Border Crossing Card', value: 'border-crossing-card' },
  { label: 'US Legal Resident Alien ID', value: 'us-legal-resident-alien-id' },
  { label: 'Travel Visa with a Photo', value: 'travel-visa-with-a-photo' },
];

// eslint-disable-next-line max-len
export const alphaeonSuspendedComment = 'Lender failed transaction';
export const alphaeonInactiveComment = 'Inactive account';
export const alphaeonUnderReview = 'Under review';
export const itinNoElibigleComment = 'itin_not_supported';
export const fraudAlertComment = 'fraud_alert';
export const duplicateApplicationComment = 'duplicate_application';
export const NotAvailable = 'N/A';

export const EXCLUDE_NOTICE_FOR_LENDERS = [
  LENDERS.genesis,
  LENDERS.aff,
  LENDERS.uga_prime,
  LENDERS.uga3,
  LENDERS.hfd_second,
  LENDERS.covered_prime,
  LENDERS.covered_extra,
  LENDERS.claritypay_2,
  LENDERS.claritypay_3,
  LENDERS.claritypay_4,
];

export const HFD_REFUND_MINIMUM_REMAINING_AMOUNT = 1;

export const SUBMISSION_ID_MIN_LENGTH = 23;

export const APPROVE_AND_CHARGE_LENDERS = [
  LENDERS.aff,
  LENDERS.snap,
];

export const LENDERS_WITH_ADDITIONAL_VERIFICATION = [
  LENDERS.carecredit,
] as Array<LENDERS_TYPE>;

export enum DynamicContentType {
  terms = 'terms',
  arbitration = 'arbitration',
  privacyPolicy = 'privacyPolicy',
  tcpa = 'tcpa',
  paymentAuth = 'paymentAuth',
  generalConsent = 'generalConsent',
}
