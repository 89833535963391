import {
  getSubmissionLenderApplicationsRequest,
} from 'store/apiRequests';
import flatten from 'lodash/flatten';
import { SubmissionApplications } from 'types/submissionApplications';
import {
  getLenderApplicationsActions,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { waitWhileCondition } from '../../utils/requestWithTimeout';
import { IShortenLenderApplication } from '../../types/submissions';
import { LENDER_APPLICATION_STATUSES } from '../../applicationConstants';

type Props = {
  waitForResponse?: boolean;
  submissionId: string
};

export const getLenderApplicationsThunk = defThunkRequest<Props, SubmissionApplications>({
  actionTypes: getLenderApplicationsActions,
  thunkSteps: [
    ({ data: { submissionId, waitForResponse } }) => {
      if (!waitForResponse) return getSubmissionLenderApplicationsRequest(submissionId);
      return waitWhileCondition({
        request: () => getSubmissionLenderApplicationsRequest(submissionId),
        condition: (data: { tiers: Array<Array<IShortenLenderApplication>>, currentTier: number }) => {
          const { tiers } = data;
          return flatten(tiers).find((x) => [
            LENDER_APPLICATION_STATUSES.callback,
            LENDER_APPLICATION_STATUSES.locked,
          ].includes(x.status) || x.inRequest,
          );
        },
        timeout: 60,
        every: 2,
      });
    },
  ],
});
