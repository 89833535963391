import React from 'react';
import {
  DialogContent,
} from '@material-ui/core';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { RegularTitle } from './RegularTitle';
import { BaseModal } from './BaseModal';
import { CloseButton } from './CloseButton';
import { AlertTitle } from './AlertTitle';
import { IconProps } from '../Icons/types';

const useStyles = makeStyles({
  dialogContent: {
    overflowX: 'hidden',
    maxHeight: ({ height }: any) => height,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    textAlign: ({ textAlign }: any) => textAlign,
  },
});

type Props = {
    onClose?: Function;
    title?: string;
    titleIcon?: (props: IconProps) => JSX.Element;
    variant?: 'modal' | 'alert';
    subtitle?: React.ReactNode;
    children: React.ReactNode;
    customWidth?: number | string;
    mobileCustomWidth?: number | string;
    height?: number;
    renderFooter?: () => React.ReactNode;
    classes?: any;
    titleParams?: any;
    textAlign?: 'center' | 'start' | 'unset'
};

export const ModalDialog = ({
  onClose,
  title,
  children,
  titleIcon,
  variant = 'modal',
  height,
  renderFooter,
  classes = {},
  subtitle,
  titleParams,
  customWidth,
  mobileCustomWidth,
  textAlign = 'center',
}: Props) => {
  const styles = useStyles({ height, textAlign });

  return (
    <BaseModal
      onClose={onClose as any}
      customWidth={customWidth}
      mobileCustomWidth={mobileCustomWidth}
      classes={{
        root: classes.root,
        scrollPaper: classes.scrollPaper,
        paper: classes.paper,
      }}
    >
      {variant === 'modal' || !titleIcon ? (
        <RegularTitle
          titleParams={titleParams}
          title={title}
          subtitle={subtitle}
          classes={{ root: classes.header }}
        />
      ) : (
        <AlertTitle
          title={title}
          titleParams={titleParams}
          titleIcon={titleIcon}
        />
      )}
      {
            onClose && (
            <CloseButton onClose={onClose as any} />
            )
        }
      <DialogContent className={cx(styles.dialogContent, classes.dialogContent)}>
        {children}
      </DialogContent>
      {renderFooter && renderFooter()}
    </BaseModal>
  );
};
