const applicantFormValues = {
  ssn: '',
  repeatSsn: '',
  lastName: '',
  middleName: '',
  firstName: '',
  email: '',
  emailRetype: '',
  phoneNumber: '',
  phoneNumberRetype: '',
  dateOfBirth: null,
  address: {
    state: '',
    city: '',
    zipcode: '',
    apartment: '',
    streetAddr: '',
  },
  differentAddress: false,
  married: '',
  spousalFirstName: '',
  spousalLastName: '',
  spousalAddress: {
    propertyStatus: '',
    state: '',
    city: '',
    zipcode: '',
    apartment: '',
    streetAddr: '',
    apartmentType: '',
  },
};

export const defaultApplicationFormValues = {
  ...applicantFormValues,
  installment: '',
  treatmentCost: '',
  driverState: '',
  driverLicence: '',
  employmentStatus: '',
  employment: {
    occupationStatus: '',
    name: '',
    phone: '',
    date: null,
  },
  agreeToTcpa: false,
  housingStatus: '',
  monthlyIncome: '',
  netAnnualIncome: '',
  housingPayment: '',
  mailingAddressSame: true,
  mailingAddress: {
    propertyStatus: '',
    state: '',
    city: '',
    zipcode: '',
    apartment: '',
    streetAddr: '',
    apartmentType: '',
  },
  employmentDate: null,
  signCheckbox: false,

  action: 'proceed',
  fromStage: 'created',
  toStage: 'funded',
  status: 'ok',
  offers: true,
  sleep: 0,
  comment: '',

  idType: '',
  idNumber: '',
  idState: '',
  additionalMonthlyIncome: '',
  issueDate: null,
  expDate: null,
  employerPhone: '',
  occupationStatus: '',
  occupation: '',
  employerName: '',
  years: '',
  months: '',
  customerPreviousVisits: '',
  isEmployed: false,
  incomeCheckbox: false,
  conditionsCheckbox: false,

  iConfirmIncome: false,
  iConfirmDisclosure: false,
  snapCheckbox: false,
  usCitizen: '',
  preapproveDisclosures: [],
  preapproveDisclosuresToken: '',
  carecredit: {
    incomeCheckbox: false,
    netIncome: '',
  },
};
