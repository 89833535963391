let lenderTokens: Record<string, string> = {};
export const setLenderTokens = (tokens: Record<string, string>) => {
  lenderTokens = tokens;
};

export const getLenderTokens = () => lenderTokens;

let last4Ssn = '';
export const setLast4Ssn = (value: string) => {
  last4Ssn = value;
};

export const getLast4Ssn = () => {
  return last4Ssn;
};
