import { defActionTypes } from '../../utils/defActionTypes';

export const validateCardAction = defActionTypes('validate_card');
export const getPowerPaySignatureAction = defActionTypes('get_powerpay_signature');
export const getAllyDisclosureAction = defActionTypes('get_ally_disclosure');
export const getAllyApproveDisclosureAction = defActionTypes('get_ally_approve_disclosure');
export const getAllySsnAction = defActionTypes('get_ally_ssn');
export const triggerAlphaeonVerificationAction = defActionTypes('trigger_alphaeon_verification');
export const getDynamicDataAction = defActionTypes('get_aff_hardpull_disclaimer');
export const setSnapPaymentDetailsAction = defActionTypes('set_snap_payment_details');
