import type { AnyAction } from 'redux';
import { RESET_APP } from 'store/app';

import { IBaseReducer } from 'types/otherData';
import { DynamicContentType } from 'applicationConstants';
import { getDynamicDataAction } from './actionTypes';

type DynamicDataReducer = Omit<IBaseReducer, 'isLoading' | 'isLoaded'> & {
  data?: {
    items: {
      type: DynamicContentType,
      value: string;
    }[];
    offset: number;
    total: number;
  };
};

const initialState: DynamicDataReducer = {
  data: undefined,
  status: 'initial',
};

export const dynamicDataReducer = (state = initialState, action: AnyAction): DynamicDataReducer => {
  switch (action.type) {
    case getDynamicDataAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getDynamicDataAction.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        data: action.payload.data,
      };
    case getDynamicDataAction.FAILURE:
      return {
        ...state,
        status: 'error',
      };
    case RESET_APP:
      return initialState;
    default:
      return state;
  }
};
