import * as Sentry from '@sentry/react';
import log from 'loglevel';
import history from 'utils/history';
import { toObject } from 'utils/params';
import { ERROR_PAGE } from 'routes';

export const captureAndReportError = ({
  error,
  extra,
  delayRedirect,
  disableRedirect,
}:{
  error: any,
  extra?: any,
  delayRedirect?: boolean
  disableRedirect?: boolean
}) => {
  const sentryEventId = Sentry.captureException(error, {
    extra,
  });
  const search = toObject(history.location.search);
  if (!disableRedirect) {
    if (delayRedirect) {
      setTimeout(() => {
        history.push(ERROR_PAGE.url({}, {
          errorTitle: error.toString(),
          errorStack: error.stack,
          sentryEventId,
          extra,
          invite: search.invite,
          ____sandboxZipCodePrefill: search.____sandboxZipCodePrefill,
        }));
      }, 300);
    } else {
      history.push(ERROR_PAGE.url({}, {
        errorTitle: error.toString(),
        errorStack: error.stack,
        sentryEventId,
        extra,
        invite: search.invite,
        ____sandboxZipCodePrefill: search.____sandboxZipCodePrefill,
      }));
    }
  }
  log.error(error);
};
