import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../applicationConstants';
import {
  FAQ_URL,
  RELEASE_NOTES_URL,
} from '../applicationConstants/envVariables';
import { includesInUnion } from '../utils/includesInUnion';

type Props = {
  isSplitItSupported?: boolean;
  isHfdServicingSupported?: boolean;
  maxPayments?: number;
  isFinalPriceEnabled?: boolean;
  isLaserAway?: boolean;
  isIdeal?: boolean;
  isMilan?: boolean;
  defaultInviteAmount?: number;
  useEmailInvite?: boolean;
  guestOnlyFlow?: boolean;
  transactionRefundsAllowed?: boolean;
  preferLowestMonthlyOffer?: boolean;
  networkType?: string;
  faqUrl?: string;
  releaseNotesUrl?: string;
  preferNonPromoTerm?: boolean;
};

export const ApplicantConfigurationContext = React.createContext<Props>({
});

export const useCcConfigurationContext = () => {
  return useContext(ApplicantConfigurationContext);
};

const useApplicationConfigurationContextValues = (): Props => {
  const authInfo = useSelector((state) => state.auth);
  const lenderConfigurations = useSelector((state) => state.configurations);

  const {
    whitelabel,
    faqUrl,
    releaseNotesUrl,
    guestOnlyFlow,
    managersOnlyRefunds,
    preferLowestMonthlyOffer,
    defaultInviteAmount,
    preferNonPromoTerm,
  } = useMemo(() => {
    return (authInfo?.network?.flags || {});
  }, [authInfo?.network?.flags]);

  const transactionRefundsAllowed = useMemo(() => {
    return !managersOnlyRefunds || includesInUnion([
      USER_ROLES.networkAdmin,
      USER_ROLES.support,
    ], authInfo?.userInfo?.role);
  }, [authInfo?.userInfo?.role, managersOnlyRefunds]);

  const faqToUse = useMemo(() => {
    if (faqUrl === undefined || faqUrl === null) {
      return FAQ_URL;
    }
    return faqUrl;
  }, [faqUrl]);

  const releaseNotesToUse = useMemo(() => {
    if (releaseNotesUrl === undefined || releaseNotesUrl === null) {
      return RELEASE_NOTES_URL;
    }
    return releaseNotesUrl;
  }, [releaseNotesUrl]);

  return useMemo(() => {
    return {
      isSplitItSupported: lenderConfigurations.data?.splitit?.isSupported,
      isHfdServicingSupported: lenderConfigurations.data?.hfdServicing,
      maxPayments: lenderConfigurations.data?.splitit?.maxPayments,
      isLaserAway: whitelabel === 'laser_away',
      isIdeal: whitelabel === 'ideal_image',
      isMilan: whitelabel === 'milan_laser',
      useEmailInvite: false,
      networkType: whitelabel,
      faqUrl: faqToUse,
      releaseNotesUrl: releaseNotesToUse,
      guestOnlyFlow: !!guestOnlyFlow,
      transactionRefundsAllowed,
      preferLowestMonthlyOffer,
      defaultInviteAmount,
      preferNonPromoTerm,
    };
  }, [
    lenderConfigurations.data?.splitit?.isSupported,
    lenderConfigurations.data?.splitit?.maxPayments,
    lenderConfigurations.data?.hfdServicing,
    whitelabel,
    faqToUse,
    releaseNotesToUse,
    guestOnlyFlow,
    transactionRefundsAllowed,
    preferLowestMonthlyOffer,
    defaultInviteAmount,
    preferNonPromoTerm,
  ]);
};

export const CcApplicationContextProvider = ({ children }: {children: React.ReactNode}) => {
  const providerContent = useApplicationConfigurationContextValues();

  return (
    <ApplicantConfigurationContext.Provider value={providerContent}>
      {children}
    </ApplicantConfigurationContext.Provider>
  );
};
